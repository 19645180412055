import { Reducer } from 'redux'
import { reducerStateModifySubstore } from 'src/redux/reducers/reducerStateModifySubstore'
import { BANKING_DASHBOARD_GENERAL_GET } from './accounts/store/bankingDashboardGeneralGet'
import { bankingAccountsInitialState, CLEAR_ACCOUNTS, GET_BANKING_ACCOUNT, GET_BANKING_DASHBOARD_ACCOUNTS, PUT_BANKING_ACCOUNT } from './accounts/store/types'
import { bankingTransactionsInitialState, CLEAR_BANKING_TRANSACTIONS, CLEAR_ERROR, GET_BANKING_ACCOUNTS } from './transactions/store/types'
import { BankingActionTypes, BankingStoreState } from './types'
import { UTILITIES_ETL_POST } from './sync/store/utilitiesEtlPost'
import { CLEAR_BANKING_ETL } from './sync/store/types'
import { UTILITIES_IMPORT_ETL_POST } from './sync/store/utilitiesImportEtlPost'
import { BANKING_TRANSACTIONS_GET } from './transactions/store/bankingTransactionsGet'
import { BANKING_TRANSACTION_SPLIT_PUT } from './transactions/store/bankingTransactionSplitPut'
import { BANKING_CATEGORIES_GET } from './dicts/store/bankingCategoriesGet'
import { BANKING_TRANSACTION_PUT } from './transactions/store/bankingTransactionPut'
import { UTILITIES_LOGIN_POST } from './sync/store/utilitiesLoginPost'
import { BANKING_ACCOUNTS_GET } from './transactions/store/bankingAccountsGet'


export const bankingInitialState: BankingStoreState = {
    transactions: bankingTransactionsInitialState,
    dicts: {},
    accounts: bankingAccountsInitialState,
    etl: {}
}


const reducerStateModify = (state: BankingStoreState, action: any) => {
    return {
        ...state,
        accounts: {
            ...state.accounts,
            ...action.object
        }
    }
}

export const AccountsActionNames: string[] = [BANKING_DASHBOARD_GENERAL_GET]

export const UtilitiesActionNames: string[] = [UTILITIES_ETL_POST, CLEAR_BANKING_ETL, UTILITIES_IMPORT_ETL_POST, UTILITIES_LOGIN_POST]

export const TransactionsActionNames: string[] = [BANKING_TRANSACTIONS_GET, CLEAR_BANKING_TRANSACTIONS, BANKING_TRANSACTION_SPLIT_PUT, 
    BANKING_TRANSACTION_PUT, BANKING_ACCOUNTS_GET]

export const DictionariesActionNames: string[] = [BANKING_CATEGORIES_GET]

const bankingReducer: Reducer<BankingStoreState, BankingActionTypes> = (state = bankingInitialState, action: BankingActionTypes) => {
    if (AccountsActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "accounts")
    else if (UtilitiesActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "etl")
    else if (TransactionsActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "transactions")
    else if (DictionariesActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "dicts")
    switch (action.type) {
        case CLEAR_ERROR:
            return { ...state, ...action }
        case GET_BANKING_DASHBOARD_ACCOUNTS:
            return { ...state, ...action }
        case GET_BANKING_ACCOUNTS:
            return { ...state, ...action }
        case GET_BANKING_ACCOUNT:
            return reducerStateModify(state, action)
        case PUT_BANKING_ACCOUNT:
            return reducerStateModify(state, action)
        case CLEAR_ACCOUNTS:
            return reducerStateModify(state, action)
        default:
            return state
    }
}

export default bankingReducer

