import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsStoreState, ProductsThunk } from "../../types"
import { FinancialProductListDTO, ProductStoreState } from "./ProductGeneralTypes"

/// FINANCIAL_PRODUCT_LIST_GET

export const FINANCIAL_PRODUCT_LIST_GET = 'FINANCIAL_PRODUCT_LIST_GET'

export interface FinancialProductListGetParams extends ApiCallBaseData {
}

export const financialProductListGet = (params: FinancialProductListGetParams): ProductsThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/financial_products/product?filter=2'

        return callGetDispatchedApiSameName<FinancialProductListDTO, ProductsStoreState, void, FinancialProductListGetParams>(
            apiService, path, dispatch, FINANCIAL_PRODUCT_LIST_GET, undefined, params);

    }
}

export interface FinancialProductListGetStoreFields extends GetObjectStoreFieldsWithParams<FinancialProductListDTO, FinancialProductListGetParams> { }

export interface FinancialProductListGetAction extends FinancialProductListGetStoreFields {
    type: typeof FINANCIAL_PRODUCT_LIST_GET
}

