

export enum LoggingAreaEnum {
    WORDS_PAIR_FORM,
    WPF_SGGST,
    FICHES_LEARN,
    DISH_VARIANT,
    DISH_RECIPE,
    WORDS_PAIR_ATTRIBUTES_RENDERER,
    WORDS_PAIR_EDIT_FORM_PARTIAL,
    AUTH_LOG,
    GENERIC_API_PASSTHROUGH,
    MEALS_PLAN,
    DISH_INFINITE_SCROLL,
    TRANSACTIONS_TABLE,
    DISHES_GET,
    BUDGET_SUBMIT,
    PRODUCT_FIN,
    MEALS_PLAN_ROWS_PRINT,
    VOWELS
}

const LoggingMap = new Map<LoggingAreaEnum, boolean>([
    [LoggingAreaEnum.WORDS_PAIR_FORM, false],
    [LoggingAreaEnum.FICHES_LEARN, false],
    [LoggingAreaEnum.WPF_SGGST, false], // Akcje API wokół pola wpisywania słowa w edycji listy słowek
    [LoggingAreaEnum.DISH_VARIANT, false], // Renderowanie aktualizacji wokół zapisywania wariantów dań
    [LoggingAreaEnum.DISH_RECIPE, false], // Renderowanie aktualizacji wokół wyświetlania dania
    [LoggingAreaEnum.WORDS_PAIR_ATTRIBUTES_RENDERER, false], //
    [LoggingAreaEnum.WORDS_PAIR_EDIT_FORM_PARTIAL, false], // Renderowanie aktualizacji wokół wyświetlania dania
    [LoggingAreaEnum.AUTH_LOG, false], // Logowanie aplikacji
    [LoggingAreaEnum.MEALS_PLAN, false], // Logowanie aplikacji
    [LoggingAreaEnum.MEALS_PLAN_ROWS_PRINT, false], // Logowanie aplikacji
    [LoggingAreaEnum.GENERIC_API_PASSTHROUGH, false],
    [LoggingAreaEnum.DISH_INFINITE_SCROLL, false],
    [LoggingAreaEnum.TRANSACTIONS_TABLE, false],
    [LoggingAreaEnum.DISHES_GET, false],
    [LoggingAreaEnum.BUDGET_SUBMIT, false],
    [LoggingAreaEnum.PRODUCT_FIN, false],
    [LoggingAreaEnum.VOWELS, true],
])

export function logCustom(area: LoggingAreaEnum, functionName: string, obj: any, subFunctionName?: string, deepCopy?: boolean) {

    const isOn = LoggingMap.get(area);
    if (isOn !== undefined && isOn === true) {
        let parentName = LoggingAreaEnum[area]
        let header = "[" + parentName + "] " + functionName;
        if (subFunctionName !== undefined)
            header += " -> " + subFunctionName;
        console.log(header)
        if (deepCopy)
            console.log(JSON.parse(JSON.stringify(obj))) // przekazywanie kopii a nie referencji - nie działa dla map
        else
            console.log(obj)
        // console.log(JSON.parse(JSON.stringify(Array.from(obj.entries()))))
    }
}

export function logInline(area: LoggingAreaEnum, functionName: string, text: string, subFunctionName?: string) {

    const isOn = LoggingMap.get(area);
    if (isOn !== undefined && isOn === true) {
        let parentName = LoggingAreaEnum[area]
        let header = "[" + parentName + "] " + functionName;


        if (subFunctionName !== undefined)
            header += " -> " + subFunctionName + ": ";
        else
            header += ": "

        header += text;
        console.log(header)
        // console.trace(header); // Jeśli chcemy drukować cały stacktrace
    }
}

/*
logCustom(LoggingAreaEnum.FICHES_LEARN, "createOriginWordOrSkip", d)
logCustom(LoggingAreaEnum.FICHES_LEARN, "createOriginWordOrSkip", w, "post")
*/

/*
logInline(LoggingAreaEnum.DISH_RECIPE, "", d)
logInline(LoggingAreaEnum.FICHES_LEARN, "", w, "")
*/