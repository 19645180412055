import { DeleteObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callDeleteDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsThunk, ProductsStoreState } from "../../types"

/// FINANCIAL_PRODUCT_DELETE

export const FINANCIAL_PRODUCT_DELETE = 'FINANCIAL_PRODUCT_DELETE'

export interface FinancialProductDeleteParams extends ApiCallBaseData {
    id: number
}

export const financialProductDelete = (params: FinancialProductDeleteParams): ProductsThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/financial_products/product/' + params.id

        return callDeleteDispatchedApiSameName<ProductsStoreState>(
            apiService, path, dispatch, FINANCIAL_PRODUCT_DELETE, params.id);

    }
}

export interface FinancialProductDeleteStoreFields extends DeleteObjectStoreFields { }

export interface FinancialProductDeleteAction extends FinancialProductDeleteStoreFields {
    type: typeof FINANCIAL_PRODUCT_DELETE
}

// export const deleteFinancialProduct = (id: number): ProductsThunk => {
//     return (dispatch, getState, { apiService }) => {
//       const state = getState() as unknown as ApplicationState
  
//       return apiService.callDispatchedApi<FinancialProductDTO>(axios.delete,
//         '/financial_products/product/'+id,
//         response => {
//           dispatch({
//             type: POST_FINANCIAL_PRODUCT,
//             product: {
//               ...state.products.product,
//               delete: {
//                 objectId: id
//               }
//             },
//           })
//         },
//         undefined,
//         undefined,
//         error => {
//           dispatch({
//             type: POST_FINANCIAL_PRODUCT,
//             product: {
//               ...state.products.product,
//               delete: {
//                 error: error
//               }
//             }
//           })
//         }
//       )
//     }
//   }
  