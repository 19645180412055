import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsStoreState, ProductsThunk } from "../../types"
import { UserAddressesDTO } from "./ProductGeneralTypes"

/// FINANCIAL_ADDRESSES_GET

export const FINANCIAL_ADDRESSES_GET = 'FINANCIAL_ADDRESSES_GET'

export interface FinancialAddressesGetParams extends ApiCallBaseData {
}

export const financialAddressesGet = (params: FinancialAddressesGetParams): ProductsThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/user/addresses'

        return callGetDispatchedApiSameName<UserAddressesDTO, ProductsStoreState, void, FinancialAddressesGetParams>(
            apiService, path, dispatch, FINANCIAL_ADDRESSES_GET, undefined, params);

    }
}

export interface FinancialAddressesGetStoreFields extends GetObjectStoreFieldsWithParams<UserAddressesDTO, FinancialAddressesGetParams> { }

export interface FinancialAddressesGetAction extends FinancialAddressesGetStoreFields {
    type: typeof FINANCIAL_ADDRESSES_GET
}

