import { FinancialProductHistoryAddDTO } from "./ProductHistoryTypes"
import { FinancialProductPersonalAccountDTO } from "./ProductPersonalAccountTypes"
import { FinancialProductAction, FinancialProductStoreFields } from "./financialProductGet"
import { ClearFinancialProductPartialAction } from "./clearFinancialProductPartial"
import { FinancialProductDeleteStoreFields, FinancialProductDeleteAction } from "./financialProductDelete"
import { FinancialProductPostStoreFields, FinancialProductPostAction } from "./financialProductPost"
import { FinancialProductPutStoreFields, FinancialProductPutAction } from "./financialProductPut"
import { FinancialProductListGetAction, FinancialProductListGetStoreFields } from "./financialProductListGet"
import { FinancialAddressesGetStoreFields, FinancialAddressesGetAction } from "./financialAddressesGet"
import { FinancialUserDatasGetAction, FinancialUserDatasGetStoreFields } from "./financialUserDatasGet"




export interface PersonDataAddressDTO {
    id: number
    street: string
    houseNumber: string
    flatNumber?: string
    town: string
    postalCode: string
    userId: number
}

export interface FinancialProductElectronicAccountDTO {
    id: number
    clientId?: string
    clientAlias?: string
    webPassword?: string
    teleId?: string
    telePassword?: string
    appPassword?: string
    appPin?: string
    authMethod?: string
}

export interface FinancialProductHistoryDTO extends FinancialProductHistoryAddDTO {
    id: number
    // dated: string
    // financialProductHistoryTypeId: number
    // financialProductId: number
    // originOverride?: string

}



export interface FinancialProductCardDebitDTO {
    id: number
    cardNumber?: string
    insurance?: string
    merchant?: string
}


export interface FinancialProductCardCreditDTO {
    id: number
    cardNumber?: string
    insurance?: string
    cardIBAN?: string
    gracePeriod?: number
    interestRate?: number
    yearlyCost?: number
    merchant?: string
    creditAmount: number
    monthlyCost?: number
}

export interface FinancialProductDepositDTO {
    id: number
    amount: number
    dateStart: string
    dateEnd: string
    percentInterest: number
    percentTax: number
    netGain?: number
    moneyOwnerId?: number
}

export interface FinancialProductDTO {
    id: number
    ownerProductId?: number
    financialProductTypeId: number
    name: string
    noticePeriod?: string
    personDataId: number
    addressCorrespondence?: PersonDataAddressDTO
    addressLiving?: PersonDataAddressDTO
    addressRegistered?: PersonDataAddressDTO
    kontomierzAccountId?: number
    shortName?: string
    mail?: string
    descriptionRichText?: string
    electronicAccounts: FinancialProductElectronicAccountDTO
    historyEntries: FinancialProductHistoryDTO[]
    cardDebits: FinancialProductCardDebitDTO
    cardCredits: FinancialProductCardCreditDTO
    deposits: FinancialProductDepositDTO
    personalAccounts: FinancialProductPersonalAccountDTO
}

export interface FinancialProductUpdateDTO {
    ownerProductId?: number
    financialProductTypeId: number
    name: string
    noticePeriod?: string
    personDataId: number
    addressCorrespondenceId?: number
    addressLivingId?: number
    addressRegisteredId?: number
    kontomierzAccountId?: number
    shortName?: string
    mail?: string
    descriptionRichText?: string

}

export interface FinancialProductAddDTO extends FinancialProductUpdateDTO {
    userId?: number
}

export interface FinancialProductsStatusDTO {
    financialProductId: number
    financialProductName: string
    status: string
    ostatniWniosekRozpoczecie?: string
    ostatnieRozpoczecie?: string
    ostatniWniosekRozwiazanie?: string
    ostatnieRozwiazanie?: string
}
export interface FinancialProductListDTO {
    products: FinancialProductsStatusDTO[]
}

export interface PersonDataDTO {
    id: number
    name: string
    surname: string
    email: string
    userId: number

}
export interface PersonDatasDTO {
    personDatas: PersonDataDTO[]
}

export interface UserAddressesDTO {
    addresses: PersonDataAddressDTO[]
}



// Clear

export const CLEAR_FINANCIAL_PRODUCT = 'CLEAR_FINANCIAL_PRODUCT'

export interface ClearFinancialProductAction{
    type: typeof CLEAR_FINANCIAL_PRODUCT
}



/// STORE

export interface ProductStoreState {
    financial_product_get?: FinancialProductStoreFields
    financial_product_post?: FinancialProductPostStoreFields
    financial_product_put?: FinancialProductPutStoreFields
    financial_product_delete?: FinancialProductDeleteStoreFields
    financial_product_list_get?: FinancialProductListGetStoreFields
    financial_addresses_get?: FinancialAddressesGetStoreFields
    financial_user_datas_get?: FinancialUserDatasGetStoreFields
}


export const productInitialState: ProductStoreState = {
}

export type ProductGeneralActionTypes = ClearFinancialProductPartialAction | FinancialProductAction | FinancialProductPostAction | FinancialProductPutAction | FinancialProductDeleteAction 
| FinancialProductListGetAction | FinancialAddressesGetAction | FinancialUserDatasGetAction | ClearFinancialProductAction;
