import { PostObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FinancialProductUpdateDTO, FinancialProductDTO } from "./ProductGeneralTypes"
import { ProductsStoreState, ProductsThunk } from "../../types"

/// FINANCIAL_PRODUCT_POST

export const FINANCIAL_PRODUCT_POST = 'FINANCIAL_PRODUCT_POST'

export interface FinancialProductPostParams extends ApiCallBaseData {
    dto: FinancialProductUpdateDTO

}

export const financialProductPost = (params: FinancialProductPostParams): ProductsThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/financial_products/product';
        
        return callPostDispatchedApiSameName<FinancialProductDTO, ProductsStoreState, FinancialProductUpdateDTO, FinancialProductPostParams>(
            apiService, path, dispatch, FINANCIAL_PRODUCT_POST, params.dto, params);

    }
}

export interface FinancialProductPostStoreFields extends PostObjectStoreFieldsWithParams<FinancialProductDTO,FinancialProductPostParams> { } { }

export interface FinancialProductPostAction extends FinancialProductPostStoreFields {
    type: typeof FINANCIAL_PRODUCT_POST
}

// export const postFinancialProduct = (product: FinancialProductUpdateDTO): ProductsThunk => {
//     return (dispatch, getState, { apiService }) => {
//       const state = getState() as unknown as ApplicationState
  
//       return apiService.callDispatchedApi<FinancialProductDTO>(axios.post,
//         '/financial_products/product',
//         response => {
//           dispatch({
//             type: POST_FINANCIAL_PRODUCT,
//             product: {
//               ...state.products.product,
//               post: {
//                 object: response.data
//               }
//             },
//           })
//         },
//         undefined,
//         product,
//         error => {
//           dispatch({
//             type: POST_FINANCIAL_PRODUCT,
//             product: {
//               ...state.products.product,
//               post: {
//                 error: error
//               }
//             }
//           })
//         }
//       )
//     }
//   }