import { PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FinancialProductDTO, FinancialProductUpdateDTO } from "./ProductGeneralTypes"
import { ProductsStoreState, ProductsThunk } from "../../types"


/// FINANCIAL_PRODUCT_PUT

export const FINANCIAL_PRODUCT_PUT = 'FINANCIAL_PRODUCT_PUT'

export interface FinancialProductPutParams extends ApiCallBaseData {
    dto: FinancialProductUpdateDTO
    id: number

}

export const financialProductPut = (params: FinancialProductPutParams): ProductsThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/financial_products/product/' + params.id
        
        return callPutDispatchedApiSameName<FinancialProductDTO, ProductsStoreState, FinancialProductUpdateDTO, FinancialProductPutParams>(
            apiService, path, dispatch, FINANCIAL_PRODUCT_PUT, params.dto, params);

    }
}

export interface FinancialProductPutStoreFields extends PutObjectStoreFields<FinancialProductDTO> { }

export interface FinancialProductPutAction extends FinancialProductPutStoreFields {
    type: typeof FINANCIAL_PRODUCT_PUT
}


// export const putFinancialProduct = (id: number, product: FinancialProductUpdateDTO): ProductsThunk => {
//     return (dispatch, getState, { apiService }) => {
//       const state = getState() as unknown as ApplicationState
  
//       return apiService.callDispatchedApi<FinancialProductDTO>(axios.put,
//         '/financial_products/product/' + id,
//         response => {
//           dispatch({
//             type: PUT_FINANCIAL_PRODUCT,
//             product: {
//               ...state.products.product,
//               // get: {
//               //   object: {
//               //     ...state.products.product.get?.object,
//               //     ...response.data
//               //   }
//               // },
//               put: {
//                 object: response.data
//               }
//             },
//           })
//         },
//         undefined,
//         product,
//         error => {
//           dispatch({
//             type: PUT_FINANCIAL_PRODUCT,
//             product: {
//               ...state.products.product,
//               put: {
//                 error: error
//               }
//             }
//           })
//         }
//       )
//     }
//   }