import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsStoreState, ProductsThunk } from "../../types"
import { PersonDatasDTO } from "./ProductGeneralTypes"

/// FINANCIAL_USER_DATAS_GET

export const FINANCIAL_USER_DATAS_GET = 'FINANCIAL_USER_DATAS_GET'

export interface FinancialUserDatasGetParams extends ApiCallBaseData {
}

export const financialUserDatasGet = (params: FinancialUserDatasGetParams): ProductsThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/user/person_datas'

        return callGetDispatchedApiSameName<PersonDatasDTO, ProductsStoreState, void, FinancialUserDatasGetParams>(
            apiService, path, dispatch, FINANCIAL_USER_DATAS_GET, undefined, params);

    }
}

export interface FinancialUserDatasGetStoreFields extends GetObjectStoreFieldsWithParams<PersonDatasDTO, FinancialUserDatasGetParams> { }

export interface FinancialUserDatasGetAction extends FinancialUserDatasGetStoreFields {
    type: typeof FINANCIAL_USER_DATAS_GET
}

