import { BankingAccountsResponseDTO } from "./types"
import { BankingStoreState, BankingThunk } from "../../types"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"


export const BANKING_ACCOUNTS_GET = 'BANKING_ACCOUNTS_GET'

export interface BankingAccountsParams extends ApiCallBaseData {
}

export const bankingAccountsGet = (params: BankingAccountsParams): BankingThunk => {
    return (dispatch, getStore, { apiService }) => {


        var path: string = '/banking/accounts'

        return callGetDispatchedApiSameName<BankingAccountsResponseDTO, BankingStoreState, void, BankingAccountsParams>(
            apiService, path, dispatch, BANKING_ACCOUNTS_GET, undefined, params);

    
    }
}

export interface BankingAccountsGetStoreFields extends GetObjectStoreFieldsWithParams<BankingAccountsResponseDTO, BankingAccountsParams> { }

export interface BankingAccountsGetAction extends BankingAccountsGetStoreFields {
    type: typeof BANKING_ACCOUNTS_GET
}

